@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


td > p {
  text-align: right;
}

.ant-table-cell {
  text-align: center;
}

.ant-table-thead > tr > th {
  text-align: center !important;
}


.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ant-btn.header-buttons:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.ant-btn:hover.header-buttons, .ant-btn.header-buttons:focus {
    color: black !important;
    border-color:white !important;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-family: "PT Sans";
  font-size: 18px;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: center;
  position: relative;
}

.tile {
  
  margin: 15px;
  margin-bottom: 0px;
  cursor: pointer;
  overflow: hidden;
  width: 18vw;
  height: 18vw;
}
.tile img {
  width: 100%;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.tile img:hover {
  
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}



.EditContactMethod {}
.login-form {
    max-width: 300px;
  }
.login-form-forgot {
    float: right;
  }
.login-form-button {
    width: 100%;
  }
.demo-infinite-container {
  
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 820px;
  }
  .demo-loading-container {
    position: relative;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }
.demo-infinite-container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 820px;
  }
  .demo-loading-container {
    position: relative;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }
.ant-input-number {
    width: 200px !important;
}
.ant-form-horizontal .ant-form-item-label {
    text-align: left;
}
.ant-form-horizontal .ant-form-item-label {
    text-align: left;
}
/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }
  
  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }
.logo {
    height: 32px;
    background: rgba(255,255,255,.2);
    margin: 16px;
}

.scroll-row {
    background-color: aquamarine;
}
