.demo-infinite-container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 820px;
  }
  .demo-loading-container {
    position: relative;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }