@import '~antd/dist/antd.css';

.logo {
    height: 32px;
    background: rgba(255,255,255,.2);
    margin: 16px;
}

.scroll-row {
    background-color: aquamarine;
}